<template>
    <div class="error">
        <div class="content">
            <img src="~/static/public/404.png" alt="" class="error_img">
            <div class="bottom">
                <h2>您访问的页面不存在，但并不是你不够可爱~~</h2>
                <p>您访问的页面可能被删除、转移，如有需要请联系官方客服:010-68060691</p>
                <ul class="list">
                    <li><img src="~/static/public/wechat.jpg" alt=""><span>微信客服二维码</span></li>
                    <li><img src="~/static/public/qq.jpg" alt=""><span>官方QQ群二维码</span></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "error_404",
        mounted() {
            document.title = "页面错误404";
        }
    }
</script>
<style scoped lang="less">
    .error{
      background: #fafafa;
        .content{
            width: 1200px;
            margin: 0 auto;
            padding:100px 0;
            text-align: center;
            .error_img{
                display: block;
                margin: 0 auto;
            }
            h2{
                font-size: 28px;
                margin-top: 70px;
                color: #00B094;
            }
            p{
                font-size: 18px;
                color: #666666;
                margin-top: 20px;
            }
            .list{
                display: flex;
                justify-content: center;
                margin-top: 60px;
                li{
                    margin-right: 60px;
                    img{
                        display: block;
                        width: 97px;
                        margin-bottom: 10px;
                    }
                    span{
                        color: #666666;
                    }
                }
                li:last-child{
                    margin-right: 0;
                }

            }
        }
    }
</style>
